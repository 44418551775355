import "./forms.css"
import { ReactComponent as FilterIcon } from "../assets/filter.svg"
import { ReactComponent as SearchIcon } from "../assets/search.svg"
import { ReactComponent as MoreIcon } from "../assets/more.svg"
import { ReactComponent as MoreFiledIcon } from "../assets/moreFiled.svg"

export default function Forms() {
    return (
        <div className='forms'>
            <div className='sub-nav'>
                <div className="search">
                    <SearchIcon />
                    <input type="text" placeholder="Rechercher des Prospects" />
                </div>
                <div className="filter"><FilterIcon />Filter</div>
            </div>

            <div className="body-section">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Titre</th>
                            <th>Description</th>
                            <th>Réponses</th>
                            <th>Complétion</th>
                            <th>Modifié le</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={"offline".includes("off") ? "off" : "on"}>offline</td>
                            <td>Contact Form</td>
                            <td>Formulaire intégré avec la page d’acceuil Olassur</td>
                            <td>65</td>
                            <td>87%</td>
                            <td>07 Aug 2024</td>
                            <td>
                                <button>
                                    <MoreIcon className="moreIcon" />
                                    <MoreFiledIcon className="moreIcon1" />
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className={"online".includes("off") ? "off" : "on"}>online</td>
                            <td>Services Form</td>
                            <td>Chaque service d’Olassur</td>
                            <td>32</td>
                            <td>12%</td>
                            <td>23 Sep 2023</td>
                            <td>
                                <button>
                                    <MoreIcon className="moreIcon" />
                                    <MoreFiledIcon className="moreIcon1" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
