import "./nav-bar.css";
import { ReactComponent as ProfileIcon } from "../assets/profile.svg"
import { ReactComponent as LogOut } from "../assets/logout.svg"
import API_BASE from "../config";
import { useNavigate } from "react-router-dom";
import { Context } from "../App";
import { useContext } from "react";

export default function NavBar({isCollapse}) {
    const { setIsAuthenticated } = useContext(Context);
    const navigate = useNavigate();
    const onLogOut = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(API_BASE + "/auth/logout", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setIsAuthenticated(false);
                    navigate('/login');
                }
            } else {
                const errorData = await response.json();
                console.log(errorData)
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    return (
        <div className={isCollapse ? "profile-container collapse" :"profile-container"}>
            <div className="profile">
                <ProfileIcon />
                <p>
                    Profile
                </p>
            </div>
            <div className="log-out" onClick={(e) => onLogOut(e)}>
                <p>
                    Log out
                </p>
                <LogOut />
            </div>
        </div>
    )
}
