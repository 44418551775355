import "./leads-table.css";
import { ReactComponent as SearchIcon } from "../assets/search.svg"
import { ReactComponent as FilterIcon } from "../assets/filter.svg"
import { ReactComponent as PlusIcon } from "../assets/plus.svg"
import { ReactComponent as MoreIcon } from "../assets/more.svg"
import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { ReactComponent as InfoIcon } from "../assets/info.svg"
import { ReactComponent as DeleteIcon } from "../assets/delete.svg"
import { ReactComponent as ModifierIcon } from "../assets/modifier.svg"
import { ReactComponent as MoreInfoIcon } from "../assets/more-info.svg"
import { ReactComponent as AddLeadsIcon } from "../assets/add-leads.svg"
import { useEffect, useState } from "react";
import API_BASE from "../config";
import DropDown from "./DropDown";

export default function LeadsTable() {
    const [register, setRegister] = useState(false);
    const [newLead, setNewLead] = useState({});
    const [formId, setFormId] = useState("");
    const [formDetails, setFormDetails] = useState(null);
    const [validMessage, setValidMessage] = useState('')
    const [leads, setLeads] = useState([]);
    const [refreshTbale, setRefreshTable] = useState(false);
    const [showMore, setShowMore] = useState([]);
    const [showDetails, setShowDetails] = useState([])
    useEffect(() => {
        fetch(API_BASE + "/leads/list", {
            credentials: "include"
        }).then(response => response.json())
            .then(res => {
                const formattedLeads = res.map(l => ({ ...l, data: Object.fromEntries(l.data.map(d => [d.name, d.value])) }));
                setLeads(formattedLeads);
                setShowMore(new Array(res.length).fill(false))
                setShowDetails(new Array(res.length).fill(false))
            }).catch(err => console.log(err))
    }, [refreshTbale])
    const onShowMore = (index) => {
        setShowMore((prev) => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        })
    }
    const onOpenRegisterDiv = () => {
        setRegister(true);
        setShowDetails(new Array(leads.length).fill(false))
    }
    const onCloseRegisterDiv = () => {
        setRegister(false);
        setNewLead(null);
        setFormId("");
        setShowDetails(new Array(leads.length).fill(false))
    }
    const AddNewLead = (e) => {
        e.preventDefault();
        fetch(API_BASE + "/leads/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                data: newLead,
                formId: formId,
            })

        })
            .then(response => response.json())
            .then(data => {
                setNewLead(null);
                setFormId("");
                setFormDetails(null);
                setRegister(false);
                setTimeout(() => {
                    if (data.error) {
                        setValidMessage("ERROR_CREATING_LEAD")
                    }
                    setValidMessage("LEAD_CREATED_SUCCESSFULLY");
                }, 100)
                setRefreshTable(p => !p)
                setTimeout(() => {
                    setValidMessage("");
                }, 5000)
            })
            .catch(err => {
                console.log(err)
                setValidMessage("ERROR_CREATING_LEAD")
                setTimeout(() => {
                    setValidMessage("");
                }, 5000)
            })
    }
    const OnDeleteLead = (id) => {
        fetch(API_BASE + "/leads/delete", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                leadId: id,
            })
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setRefreshTable(p => !p)
                window.confirm("voullez-vous vraiment supprimer ce prospect")
            }).catch(err => console.log(err))
    }
    const [index, setIndex] = useState();
    const OnShowDetails = (index) => {
        setRegister(false)
        setShowDetails((prev) => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        })
        setIndex(index)
        setShowMore(new Array(leads.length).fill(false))
    }
    const onCloseDetails = () => {
        setShowDetails(new Array(leads.length).fill(false));
    }

    const [inputValue, setInputValue] = useState('');
    const handleChangeInput = (e) => {
        setInputValue(e.target.value);
    }

    const [isOpenTypeDropDown, setIsOpenTypeDropDown] = useState(false);
    const toggleDropdown = () => {
        setIsOpenTypeDropDown(!isOpenTypeDropDown)
    }
    const handleFormSelect = (formId) => {
        setIsOpenTypeDropDown(false)
        setFormId(formId)
    }

    useEffect(() => {
        if (!formId) {
            return;
        }
        const params = new URLSearchParams();
        params.set("formId", formId);
        const target = API_BASE + "/forms/details?" + params.toString();
        fetch(target)
            .then(res => res.json())
            .then(res => {
                setFormDetails(res);
            })
    }, [formId])

    useEffect(() => {
        if (!formDetails) {
            return;
        }
        setNewLead(Object.fromEntries(formDetails.fields.map(f => ([f.name, ""]))));
    }, [formDetails])

    const [formsList, setFormsList] = useState([]);
    useEffect(() => {
        fetch(API_BASE + "/forms/list", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        }).then(response => response.json())
            .then(data => {
                setFormsList(data)
            }).catch(err => console.log(err))
    }, [])
    return (
        <div className='Leads-Table'>
            <div className="header">
                <div className='sub-nav'>
                    <div className="search">
                        <SearchIcon />
                        <input type="text"
                            placeholder="Rechercher"
                            value={inputValue}
                            onChange={handleChangeInput}
                        />
                    </div>
                    <div className="filter"><FilterIcon />Filter</div>
                    <button onClick={onOpenRegisterDiv}>Créer un prospect <PlusIcon /></button>
                </div>
            </div>
            {
                validMessage &&
                <div className={validMessage.includes('SUCCESSFULLY') ? "success-message" : "error-message"} >{validMessage}</div>
            }
            <div className="table-container">
                <div className={register || showDetails[index] ? "table active" : "table"}>
                    <table>
                        <thead>
                            <tr>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>E-mail</th>
                                <th>Numéro de Téléphone</th>
                                <th>Générer à</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                leads
                                    .filter(lead => lead.data.firstName.concat(lead.data.lastName).toLowerCase().includes(inputValue.toLowerCase()))
                                    .map((lead, index) => (
                                        <tr key={lead.leadId}>
                                            <td>{lead.data.firstName}</td>
                                            <td>{lead.data.lastName}</td>
                                            <td>{lead.data.email}</td>
                                            <td>{lead.data.phone}</td>
                                            <td>{((new Date(lead.metadata.createdAt)).toDateString() + " — " + (new Date(lead.metadata.createdAt)).getHours() + ":" + (new Date(lead.metadata.createdAt)).getMinutes().toString().padEnd(2, "0"))}</td>
                                            <td className="more">
                                                <button onClick={() => onShowMore(index)}>
                                                    <MoreIcon className={showMore[index] ? "more-icon hidden" : "more-icon"} />
                                                    {/* <MoreFiledIcon className={showMore[index] ? "more-filed-icon active" :"more-filed-icon"} /> */}
                                                </button>
                                            </td>
                                            <td className={showMore[index] ? "menu active" : "menu"}>
                                                <div className="details" onClick={() => OnShowDetails(index)} ><InfoIcon />Plus de détails</div>
                                                <div className="modify" ><ModifierIcon />Modifier le prospect</div>
                                                <div className="delete" onClick={() => OnDeleteLead(lead.leadId)} ><DeleteIcon />Supprimer le prospect</div>
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
                {/* <button className="open-btn" onClick={onOpenRegisterDiv}><span><PlusIcon /></span> Créer un prospect </button> */}
                <div className={register ? "register-side-div active" : "register-side-div"}>
                    <div className="side-div-header">
                        <h3><AddLeadsIcon /> Créer un prospect </h3> <CloseIcon className="closeIcon" onClick={onCloseRegisterDiv} />
                    </div>
                    <form action="" onSubmit={(e) => AddNewLead(e)}>
                        <DropDown
                            defaultValue={"veuillez choisir un formulaire"}
                            toggleDropdown={toggleDropdown}
                            setIsOpen={setIsOpenTypeDropDown}
                            isOpen={isOpenTypeDropDown}
                            selectedOption={formId}
                            handleOptionClick={handleFormSelect}
                            options={formsList.map((form) => ({ value: form.formId, title: form.formTitle }))}
                        />
                        {
                            formDetails && newLead &&
                            formDetails?.fields?.map(f => (
                                <input
                                    key={f.name}
                                    type="text"
                                    placeholder={f.title}
                                    value={newLead[f.name] || ""}
                                    onChange={(e) => setNewLead(l => ({ ...l, [f.name]: e.target.value }))}
                                />
                            ))
                        }
                        <button>Créer un prospect <PlusIcon /> </button>
                    </form>
                </div>
                <div className={showDetails[index] ? "details-side-div active" : "details-side-div"}>
                    <div className="side-div-header">
                        <h3><MoreInfoIcon />Details du prospect </h3> <CloseIcon className="closeIcon" onClick={onCloseDetails} />
                    </div>
                    <form action="">
                        <input type="text"
                            placeholder="Nom complet"
                            value={leads[index]?.fullName}
                            readOnly
                        />
                        <input type="text"
                            placeholder="e-mail"
                            value={leads[index]?.email}
                            readOnly
                        />
                        <input type="text"
                            placeholder="numero de telephone"
                            value={leads[index]?.phone}
                            readOnly
                        />
                        <textarea type="text"
                            placeholder="ecrire un message"
                            value={leads[index]?.message}
                            readOnly
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
