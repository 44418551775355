import "./products.css";
import { ReactComponent as SearchIcon } from "../assets/search.svg"
import { ReactComponent as FilterIcon } from "../assets/filter.svg"
import { ReactComponent as PlusIcon } from "../assets/plus.svg"
import { ReactComponent as MoreIcon } from "../assets/more.svg"
import { ReactComponent as MoreFiledIcon } from "../assets/moreFiled.svg"
import { ReactComponent as CloseIcon } from "../assets/close.svg"
import DropDown from "./DropDown";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as InfoIcon } from "../assets/info.svg"
import { ReactComponent as DeleteIcon } from "../assets/delete.svg"
import { ReactComponent as PhotoIcon } from "../assets/photo.svg"
import { ReactComponent as ModifierIcon } from "../assets/modifier.svg"
import { ReactComponent as UploadIcon } from "../assets/upload.svg"
import { ReactComponent as UploadBtnIcon } from "../assets/uploadbtn.svg"
import API_BASE from "../config";
import { PopupContext } from "../PopupContext";

export default function Products() {
    const [isOpenTypeDropDown, setIsOpenTypeDropDown] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [products, setProducts] = useState([]);
    const [productName, setProductName] = useState("");
    const [descp, setDescp] = useState("");
    const [file, setFile] = useState([]);
    const [mediaId, setMediaId] = useState("");
    const [openAddDiv, setOpenAddDiv] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [mediaIds, setMediaIds] = useState([]);
    const { openPopUp, setOpenPopUp } = useContext(PopupContext);
    const [images, setImages] = useState([]);
    const [imagesUploaded, setImagesUploaded] = useState([])
    const [isShowMore, setIsShowMore] = useState([]);
    // const [imagePreviw, setImagePreviw] = useState();
    const { imagePreviw, setImagePreviw } = useContext(PopupContext);
    const [srcImg, setSrcImage] = useState("")
    const onOpenPopUp = () => {
        setOpenPopUp(true)
    }
    const onClosePopUp = () => {
        setOpenPopUp(false);
        setFile([])
    }
    const popupRef = useRef()
    const popupImageRef = useRef();
    const handleClickOutside = useCallback((event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setOpenPopUp(false)
        }
        if (popupImageRef.current && !popupImageRef.current.contains(event.target)) {
            setImagePreviw(new Array(products.length).fill(false))
        }
    }, [setOpenPopUp, setImagePreviw, products.length]);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        fetch(API_BASE + "/products/list", {
            method: "GET",
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                setProducts(data)
                setIsShowMore(new Array(data.length).fill(false))
                setImagePreviw(new Array(data.length).fill(false))
            })
            .catch(err => console.log(err))
        fetch(API_BASE + "/media/list", {
            method: "GET",
            credentials: "include"
        }).then(response => response.json())
            .then(data => {
                setMediaIds(data)
            }).catch(err => console.log(err))
    }, [refresh, setImagePreviw])

    const fetchImages = useCallback(async () => {
        try {
            const imageFetches = products.map(async (product) => {
                const response = await fetch(API_BASE + `/products/media?productId=${product?.productId}`, {
                    method: "GET",
                    credentials: "include"
                });
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                return { [product.productId]: url };
            });

            const imageObjects = await Promise.all(imageFetches);
            const imageMap = imageObjects.reduce((acc, imageObject) => {
                return { ...acc, ...imageObject };
            }, {});

            setImages(imageMap);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }, [products]);


    const fetchImagesUpladed = useCallback(async () => {
        try {
            const imageFetches = mediaIds?.map(async (mediaId) => {
                const response = await fetch(API_BASE + `/media/?mediaId=${mediaId}`, {
                    method: "GET",
                    credentials: "include"
                });
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                return { [mediaId]: url };
            });

            const imageObjects = await Promise.all(imageFetches);
            const imageMap = imageObjects.reduce((acc, imageObject) => {
                return { ...acc, ...imageObject };
            }, {});

            setImagesUploaded(imageMap);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }, [mediaIds]);

    useEffect(() => {
        fetchImages()
        fetchImagesUpladed()
    }, [fetchImages, fetchImagesUpladed])
    const toggleDropdownType = () => {
        setIsOpenTypeDropDown(!isOpenTypeDropDown);
    };
    const handleOptionClick = (option) => {
        setSelectedType(option);
        setIsOpenTypeDropDown(false);
    };
    const OnOpenAddDiv = () => {
        setOpenAddDiv(true)
    }
    const OnCloseAddDiv = () => {
        setOpenAddDiv(false)
        setProductName('')
        setDescp('')
        setSelectedType("")
        setFile([])
    }
    const onUploadMedia = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        fetch(API_BASE + "/media/upload", {
            method: "POST",
            body: formData,
            credentials: "include"
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setFile([{ imgId: data.data.mediaId, nameImg: "product Image" }])
                setRefresh(p => !p)
            })
            .catch(err => console.log(err))
    }
    const onAddProduct = (e) => {
        e.preventDefault();
        fetch(API_BASE + "/products/create", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: productName,
                type: selectedType,
                description: descp,
                mediaId: mediaId
            }),
            credentials: 'include'
        }).then(response => response.json())
            .then(data => {
                console.log(data)
                setOpenAddDiv(false)
                setProductName("")
                setDescp("");
                setFile([]);
                setMediaId("");
                setSelectedType(null)
                setRefresh(p => !p)
            }).catch(err => console.log(err))
    }

    const onShowMore = (index) => {
        setIsShowMore((prev) => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        });
    }
    const onPreviwImage = (i, idProduct) => {
        setImagePreviw((prev) => {
            let newValues = [...prev];
            newValues[i] = true;
            return newValues;
        })
        setSrcImage(idProduct)
        setIsShowMore(new Array(products.length).fill(false))
    }
    const onDeleteProduct = async (productId) => {
        console.log(productId)
        await fetch(API_BASE + "/products/delete", {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ productId: productId })
        }).then(response => response.json())
            .then(data => {
                console.log(data)
                setRefresh(p => !p)
            })
            .catch(err => console.log(err))
    }
    const [inputValue, setInputValue] = useState('');
    const handleChangeInput = (e) => {
        setInputValue(e.target.value);

    }
    return (
        <div className="products">
            <div className="products-header">
                <div className='sub-nav'>
                    <div className="search">
                        <SearchIcon />
                        <input type="text"
                            placeholder="Rechercher"
                            value={inputValue}
                            onChange={handleChangeInput}
                        />
                    </div>
                    <div className="filter"><FilterIcon />Filter</div>
                    <button onClick={OnOpenAddDiv}>Ajouter un Produit <PlusIcon /></button>
                </div>
            </div>
            <div className="products-container">
                <div className={openAddDiv ? "products-list active" : "products-list"}>
                    {
                        products.map((product, index) => {
                            return (
                                <Fragment key={product.productId}>
                                    <div className="product-card" key={index}>
                                        <div className="product-card-header">
                                            <p className="product-name">{product.productName} </p>
                                            <button onClick={() => onShowMore(index)}>
                                                <MoreIcon className={isShowMore[index] ? "moreIcon active" : "moreIcon"} />
                                                <MoreFiledIcon className={isShowMore[index] ? "moreIcon1 active" : "moreIcon1"} />
                                            </button>
                                            <div className={isShowMore[index] ? "more-div active" : "more-div"}>
                                                <div className="details" ><InfoIcon />Plus de détails</div>
                                                <div className="details" onClick={() => { onPreviwImage(index, product.productId) }} ><PhotoIcon />Apperçu de photo</div>
                                                <div className="modify" ><ModifierIcon />Modifier le prospect</div>
                                                <div className="delete" onClick={() => onDeleteProduct(product.productId)} ><DeleteIcon />Supprimer le prospect</div>
                                            </div>
                                        </div>
                                        <div className="product-image">
                                            <img src={images[product.productId]} alt="" />
                                        </div>
                                        <p className="product-type">{product.productType}</p>
                                    </div>
                                    <div className={imagePreviw[index] ? "preview-img active" : "preview-img"} ref={popupImageRef}>
                                        <div className="preview-img-header">
                                            <h3>visualisation de l'image</h3>
                                            <CloseIcon onClick={() => setImagePreviw(new Array(products.length).fill(false))} />
                                        </div>
                                        <img src={images[srcImg]} alt="" />
                                    </div>
                                </Fragment>
                            )
                        }
                        )
                    }
                </div>
                <div className={openAddDiv ? "add-product active" : "add-product"}>
                    <div className="side-div-header">
                        <h3> Ajouter un produit </h3>
                        <CloseIcon className="closeIcon" onClick={OnCloseAddDiv} />
                    </div>
                    <form action="">
                        <input type="text"
                            placeholder="Nom produit"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                        <DropDown
                            options={
                                [
                                    { id: 0, label: "audit-services" },
                                    { id: 1, label: "life" },
                                    { id: 2, label: "family" }
                                ]}
                            isOpen={isOpenTypeDropDown}
                            setIsOpen={setIsOpenTypeDropDown}
                            handleOptionClick={handleOptionClick}
                            toggleDropdown={toggleDropdownType}
                            selectedOption={selectedType}
                            defaultValue={"Veuillez Choisir une option"}
                        />
                        <p>(Cette Description sera affichée directement sur la page officielle)</p>
                        <input type="text"
                            placeholder="Décrire le produit"
                            value={descp}
                            onChange={(e) => setDescp(e.target.value)}
                        />
                        <div className="media">
                            <div className="input-media">
                                <PhotoIcon />
                                {
                                    file.length !== 0 ? file[0].nameImg : "Aucun Element attaché"
                                }
                            </div>
                            <div className="upload-icon" onClick={onOpenPopUp}>
                                <UploadIcon />
                            </div>
                        </div>
                        <div className="buttons">
                            <button onClick={(e) => onAddProduct(e)}>Sauvegarder </button>
                            <button onClick={(e) => { e.preventDefault(); OnCloseAddDiv() }}>Annuler </button>
                        </div>
                    </form>
                </div>
                <div className={openPopUp ? "pop-up-img active" : "pop-up-img"} ref={popupRef}>
                    <div className="pop-up-header">
                        <h3>Veuillez Choisir une image</h3>
                        <CloseIcon onClick={onClosePopUp} />
                    </div>
                    <div className="buttons-container">
                        <button className={file.length !== 0 ? "btn active" : "btn"}
                            onClick={() => {
                                setOpenPopUp(false);
                                setMediaId(file[0]?.imgId)
                            }
                            }
                        >Confirmer mon choix
                        </button>
                        <button
                            className={file.length !== 0 ? "annulerBtn active" : "annulerBtn"}
                            onClick={() => {
                                setOpenPopUp(false);
                                setFile([])
                            }}>
                            Annuler
                        </button>
                    </div>
                    <div className="images">
                        {
                            mediaIds.map((id, index) => (
                                <div className="image" key={index} style={(file.length !== 0 && file[0].imgId) === id ? { border: "2px solid #33bbff" } : {}}>
                                    <img
                                        src={imagesUploaded[id]}
                                        alt={id}
                                        onClick={(e) => {
                                            const clickedImgId = e.target.alt;
                                            setFile((prev) => {
                                                if (prev.length > 0 && prev[0].imgId === clickedImgId) {
                                                    return [];
                                                } else {
                                                    return [{ imgId: clickedImgId, nameImg: "product image" }];
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className="buttons">
                        <div>OU</div>
                        <div className="uploadbtn">
                            <input type="file"
                                onChange={(e) => {
                                    onUploadMedia(e.target.files[0]);
                                }}
                            />
                            <button><UploadBtnIcon />Importer une photo</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

