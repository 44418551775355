import "./login.css"
import { ReactComponent as UsernameIcon } from "../assets/username.svg"
import { ReactComponent as PasswordIcon } from "../assets/password.svg"
import { ReactComponent as PreviewIcon } from "../assets/preview.svg"
import { ReactComponent as LoginIcon } from "../assets/login.svg"
import { useContext, useEffect, useState } from "react"
import { Context } from "../App"
import { useNavigate } from "react-router-dom"
import API_BASE from "../config"

export default function Login() {
    const { isAuthenticated, isChecked, setIsAuthenticated } = useContext(Context);
    const navigate = useNavigate();
    useEffect(() => {
        if (isChecked && isAuthenticated) {
            navigate('/manage');
        };
    }, [isChecked, isAuthenticated, navigate])

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await fetch(API_BASE + '/auth/login', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setIsAuthenticated(true);
                    navigate('/manage');
                }
                else {
                    setIsAuthenticated(false);
                    setError(data.error)
                }
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Login failed');
            }
            // }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred. Please try again.');
        }
    };
    if (isChecked && !isAuthenticated) {
        return (
            <div className="login-page">
                <div className="logo">
                    <img src="images/verticalLogo.png" alt="" />
                    <p><span>Your Expertise </span> Powers Our Assurance</p>
                </div>
                <div className="login">
                    <div className="login-form">
                        <div>
                            <h1>Welcome Back</h1>
                            <p>Enter your Olassur account details </p>
                        </div>
                        <form action="" onSubmit={handleLogin}>
                            <div className="inputs">
                                <div>
                                    <UsernameIcon />
                                    <input type="text"
                                        placeholder="username"
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <PasswordIcon />
                                    <input type="password"
                                        placeholder="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <PreviewIcon />
                                </div>
                            </div>
                            <button>Log In <LoginIcon /></button>
                            <p style={{color : "red", fontSize : "18px"}}>{error}</p>
                        </form>
                        <p>Forget password</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return(<></>)
    }
}
