import "./manage1.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { ReactComponent as ArrowLeft } from "../assets/arrow-left.svg"
import Tabs from "./Tabs";
import { ReactComponent as LeadsIcon } from "../assets/leads.svg"
import { ReactComponent as ProductIcon } from "../assets/product.svg"
import { ReactComponent as FormsIcon } from "../assets/form.svg"
import { ReactComponent as CorbeilleIcon } from "../assets/corbeille.svg"
import NavBar from "./NavBar";
import LeadsTable from "./LeadsTable";
import Products from "./Products";
import Forms from "./Forms";

export default function Manage1() {
    const { isChecked, isAuthenticated } = useContext(Context);
    const navigate = useNavigate();
    const [isCollapse, setIsCollapse] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        if (isChecked && !isAuthenticated) {
            navigate("/login");
        };
    });
    const onCollapseSideBar = () => {
        console.log()

        setIsCollapse(p => !p)
    }
    return (
        <div className='manage'>
            <div className="side-bar">
                <div className={isCollapse ? "side-bar-header active" : "side-bar-header"}>
                    <h2
                        style={isCollapse ? { display: "none" } : { display: 'inline' }}
                    >Espaces de travail</h2>
                    <ArrowLeft className='icon1' onClick={onCollapseSideBar} />
                </div>
                <div className="side-bar-body">
                    <Tabs
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        isCollapse={isCollapse}
                        titles={[
                            {
                                icon1: <LeadsIcon className="icon1" />,
                                title: "Prospects"
                            },
                            {
                                icon1: <ProductIcon className="icon1" />,
                                title: "Produits"
                            },
                            {
                                icon1: <FormsIcon className="icon1" />,
                                title: "Formulaires"
                            },
                            {
                                icon1: <CorbeilleIcon className="icon1" />,
                                title: "Corbeille"
                            }
                        ]} />
                    <NavBar isCollapse={isCollapse} />
                </div>
            </div>
            <div className="manager-body">
                {
                    currentIndex === 0 &&
                    <div className="leads" style={{ display: "flex", overflow: "hidden" }}>
                        <LeadsTable />
                    </div>
                }
                <div className="products" style={currentIndex === 1 ? { display: "flex" } : { display: "none" }}>
                    <Products />
                </div>
                <div className="forms" style={currentIndex === 2 ? { display: "flex" } : { display: "none" }}>
                    <Forms />
                </div>
                <div className="Corbeille" style={currentIndex === 3 ? { display: "flex" } : { display: "none" }}>
                    hello Corbeille
                </div>
            </div>
        </div>
    )
}
