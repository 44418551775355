import './App.css';
import './reset.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from "react-router-dom";
import Login from './Components/Login';
import { createContext, useContext, useEffect, useState } from 'react';
import API_BASE from './config';
import Home from './Components/Home';
// import Manage from './Components/Manage';
import { PopupContext } from './PopupContext';
import Manage1 from './Components/Manage1';
export const Context = createContext(false);
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    fetch(API_BASE + '/auth/check', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then((data) => {
        const { error, success } = data;
        setIsChecked(true);
        if (error) {
          console.log(error);
          setIsAuthenticated(false);
        } else if (success) {
          setIsAuthenticated(true);
        };
      })
      .catch((err) => {
        console.error("Error:", err.message);
        setIsAuthenticated(false);
      });
  }, [isAuthenticated, isChecked]);
  const { openPopUp, imagePreviw: imagePreview } = useContext(PopupContext);
  const imageIndex = imagePreview?.findIndex(value => value === true)
  return (
    <Context.Provider value={{ isAuthenticated, isChecked, setIsAuthenticated }}>
      <div className="App">
        {
          (openPopUp === true || imageIndex !== -1 ) && 
          <div className="overLay">
          </div>
        }
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/manage" element={<Manage1 />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Context.Provider >
  );
}

export default App;
