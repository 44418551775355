import { useCallback, useEffect, useRef } from "react";
import "./drop-down.css";
import { ReactComponent as CaretDown } from "../assets/caretDown.svg";

export default function DropDown({ defaultValue, options, isOpen, handleOptionClick, setIsOpen, toggleDropdown, selectedOption }) {

    const classList = ["dropdown-toggle"]
    if (isOpen) {
        classList.push("open")
    }

    const dropDownRef = useRef()
    const handleClickOutside = useCallback((event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, [setIsOpen]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    return (
        <div className="dropdown" ref={dropDownRef}>
            <div className={classList.join(" ")} onClick={toggleDropdown}>
                {options.find(o => o.value === selectedOption)?.title || defaultValue}
                <CaretDown className={isOpen ? 'caret Up' : 'caret'} />
            </div>
            {isOpen && (
                <ul className="dropdown-menu">
                    {options.map((option) => (
                        <li key={option.value} onClick={() => {
                            handleOptionClick(option.value);
                            if (!(typeof onclose === "function")) {
                                return;
                            }
                        }}>
                            {option.title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
