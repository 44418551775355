import React, { createContext, useState } from 'react';

const PopupContext = createContext();

const PopupProvider = ({ children }) => {
    const [openPopUp, setOpenPopUp] = useState(false);
    const [imagePreviw, setImagePreviw] = useState([]);
    return (
        <PopupContext.Provider value={{ openPopUp, setOpenPopUp, imagePreviw, setImagePreviw }}>
            {children}
        </PopupContext.Provider>
    );
};

export { PopupProvider, PopupContext };
