import React from 'react'
import "./tabs.css"

export default function Tabs({ titles, isCollapse, currentIndex, setCurrentIndex }) {
    const classList = ["tabs-container"];
    isCollapse && classList.push("collapsed");
    const handleClick = (index) => {
        setCurrentIndex(index)
    }
    return (
        <div className={classList.join(" ")}>

            <div className='tabs'>
                {
                    titles.map((title, index) => (
                        <div className={(index === currentIndex) ? "tab active" : 'tab'}
                            key={index}
                            data-index={index}
                            onClick={() => handleClick(index)}>
                            <span className="icon">{title.icon1}</span>
                            <p>{title.title}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
